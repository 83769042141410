export const homeObjDiscover = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Discover Lists',
    headline: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    buttonLabel: 'Try Now',
    imgStart: false,
    img: '../../svg/discover.svg',
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true,
}

export const homeObjBrowse = {
    id: 'browse',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Browse Items',
    headline: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    buttonLabel: 'Try Now',
    imgStart: true,
    img: '../../svg/browse.svg',
    alt: 'Piggybank',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjProfile = {
    id: 'profile',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Profile',
    headline: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    buttonLabel: 'Try Now',
    imgStart: false,
    img: '../../svg/profile.svg',
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true,
}

export const homeObjListsChallenge = {
    id: 'lists-challenge',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Challenge Lists',
    headline: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    buttonLabel: 'Try Now',
    imgStart: true,
    img: '../../svg/challenage.svg',
    alt: 'Paper',
    dark: true,
    primary: true,
    darkText: false,
}

export const homeObjListsCollabrative = {
    id: 'lists-collabrative',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Collabrative Lists',
    headline: 'Lorem ipsum dolor sit amet',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    buttonLabel: 'Try Now',
    imgStart: false,
    img: '../../svg/collabrative.svg',
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true,
}