import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from "./ServicesElements";

const Services = () => {
    const maleSrc = "../../svg/male_avatar.svg";
    const femaleSrc = "../../svg/female_avatar.svg";
    
    return (
        <ServicesContainer id="reviews">
            <ServicesH1>User Reviews</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={maleSrc}></ServicesIcon>
                    <ServicesH2>John Deo</ServicesH2>
                    <ServicesP>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={femaleSrc}></ServicesIcon>
                    <ServicesH2>Jimmy Sehgal</ServicesH2>
                    <ServicesP>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={maleSrc}></ServicesIcon>
                    <ServicesH2>John Deo</ServicesH2>
                    <ServicesP>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
