import React, { useState } from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import InfoSection from '../components/InfoSection';
import { homeObjBrowse, homeObjDiscover, homeObjListsChallenge, homeObjListsCollabrative, homeObjProfile } from '../components/InfoSection/Data';
import Services from '../components/Services';
import Footer from '../components/Footer';
// import { BrowserRouter as Router } from "react-router-dom";


const Home = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
        {/* <Router> */}
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection></HeroSection>
            <div id='features'>
                <InfoSection {...homeObjDiscover}></InfoSection>
                <InfoSection {...homeObjBrowse}></InfoSection>
                <InfoSection {...homeObjProfile}></InfoSection>
                <InfoSection {...homeObjListsChallenge}></InfoSection>
                <InfoSection {...homeObjListsCollabrative}></InfoSection>
            </div>
            <Services></Services>
            {/* <InfoSection {...homeObjThree}></InfoSection> */}
            <Footer></Footer>
        {/* </Router> */}
        </>
    )
}

export default Home
