import React from 'react'
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarRoute, SidebarMenu, SidebarLink, SideBtnWrap } from "./SidebarElements";

const Sidebar = ({isOpen, toggle}) => {
    return (
        <>
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon></CloseIcon>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to='about' onClick={toggle}>
                        About
                    </SidebarLink>
                    <SidebarLink to='features' onClick={toggle}>
                        Features
                    </SidebarLink>
                    <SidebarLink to='reviews' onClick={toggle}>
                        User Reviews
                    </SidebarLink>
                    {/* <SidebarLink to='signup' onClick={toggle}>
                        Sign Up
                    </SidebarLink> */}
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='/blog'>Blog</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
            
        </>
    )
}

export default Sidebar
